<template>
	<b-card>
		<b-row class="mb-2">
			<b-col cols="12" md="6">
				<router-link
					to="/create-progressive"
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
					v-if="checkPermission('add progressive')"
				>
					Create Progressive
				</router-link>
			</b-col>
		</b-row>
		<b-table 
			striped 
			hover 
			:items="progresives"
			:fields="fields"
			:busy="isLoading"
			show-empty
			responsive
		>
			<template #table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>

			<template v-slot:cell(no)="{ index }">
				{{ (currentPage - 1) * 10 + index + 1 }}
			</template>	
			
			<template v-slot:cell(actions)="{ item }">
				<div class="d-flex" style="gap:12px;">
					<router-link
						:to="'/edit-progressive/'+ item.uuid"
						v-if="checkPermission('update progressive')"
					>
						<b-badge 
							variant="warning"
							class="badge-glow"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-edit-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								v-b-tooltip.hover.bottom="'Edit Data'"
							/>
						</b-badge>
					</router-link>

					<b-badge 
						variant="danger"
						class="badge-glow"
					>
						<feather-icon
							:id="`invoice-row-${item.uuid}-delete-icon`"
							icon="TrashIcon"
							size="16"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							v-if="checkPermission('delete progressive')"
							@click="deleteItem(item.uuid)"
						/>
					</b-badge>
				</div>
			</template>
		</b-table>
		<div
			v-if="result.total > 0"
			class="m-1"
		>
			<div class="row">
				<div class="col mb-1">
					<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
				</div>
				<div class="col">
					<pagination
						:data="result"
						:limit="4"
						align="right"
						@pagination-change-page="getData"
					/>
				</div>
			</div>
		</div>
	</b-card>
</template>

<script>
import { 
	BCard,
	BCardText,
	BTable,
	BPagination,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBTooltip,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
export default {
	components: {
		BCard,
		BCardText,
		BTable,
		BPagination,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	data() {
		return {
			currentPage: 1,
			result:{},
			fields: ['no', 'year', 'count', 'actions'],
			progresives: [],
			isLoading: false,
		}
	},

	computed: {
		rows() {
			return this.progresives.length
		}
	},

	setup() {
		return {
			checkPermission,
			successNotification, 
			errorNotification
		}
	},

	methods: {
		getData() {
			this.isLoading = true
			this.$http.get('admin/progressives', {
			})
				.then(response => {
				this.result = response.data.data
				this.progresives = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			})
		},
		deleteItem(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Anda akan menghapus data Progressive ini. Data yang sudah terhapus tidak dapat dipulihkan.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Ya, hapus data ini',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.delete('admin/progressives/' + item)
					this.getData(this.currentPage)
					successNotification(this, 'Success', 'Progressive berhasil dihapus!')
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', 'Ada masalah teknis!')
				}
			})
		}
	},

	created() {
		this.getData();
	}
}
</script>